/*=======================================*\ 
  Estilos para Sliders (Carrusel)
\*=======================================*/
/* Carousell (flickity) */
.carousel-image {
  height: 200px;
  width: 138px;
  display: flex;
  background: rgb(212, 212, 212);
}
.carousel-image img {
  height: 100%;
}
/* Carousel Banner (flickity) */
.hero-banner {
  height: 400px;
  width: 1365px;
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-banner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.home-subtitle {
  color: var(--principal);
  margin: 20px 0 0px;
  font-size: 25px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 520px) {
    font-size: 18px;
  }
}
.home-detail {
  color: var(--principal);
  margin: 5px 0 30px;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
  font-size: 18px;

  @media (max-width: 520px) {
    font-size: 14px;
  }
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #9999999a;
  z-index:100;
}

.my-float{
	margin-top:16px;
}

/*=======================================*\
  flickity.css personalizado
\*=======================================*/
/* ---- flickity-button ---- */

.hero-carousel .flickity-button {
  position: absolute;
  background: none;
  border: 4px solid #ffffff;
  opacity: 0.5;
  color: #ffffff;
}

.hero-carousel {
  opacity: 0.8;
  cursor: pointer;
}

.hero-carousel .flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.hero-carousel .flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.hero-carousel .flickity-prev-next-button {
  top: 50%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}
.hero-carousel .flickity-prev-next-button.previous {
  left: 60px;
}
.hero-carousel .flickity-prev-next-button.next {
  right: 60px;
}

.filter .react-select-container {
  min-height: 40px;

  .react-select__control {
    min-height: 40px;
    border-radius: 8px;
    border-color: transparent;
    background-color: #ffffffa8;
    transition: background-color 200ms ease, outline 200ms ease,
      color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;

    &:hover,
    &:active {
      border-color: rgba(0, 0, 0, 0.1);
      // box-shadow: 0 0 0 4px rgba(234, 76, 137, 0.1);
      box-shadow: 0 0 0 4px rgba(234, 76, 137, 0.445);
      background-color: #ffffffda;
    }
  }

  .react-select__control--is-focused {
    border-color: rgba(234, 76, 137, 0.788);
    box-shadow: 0 0 0 4px rgba(234, 76, 137, 0.445);
    background-color: #ffffffda;
  }

  .react-select__multi-value {
    background-color: #fff;
  }
}