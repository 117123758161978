/*=======================================*\ 
  GRID
\*=======================================*/

main {
  display: flex;
  flex-wrap: wrap;
  transition: all 0.4s;
}
.character {
  position: relative;
  transition: 0.4s;
  border: none;
  width: 18%;
  margin: 1%;

  &:hover .character_img {
    transform: scale(1.15);
  }

  &:hover .badge-verified {
    
    span {
      transition: all .3s ease;
      font-size: 0px;
    }
  }

  &:hover .badge-vip,
  &:hover .badge-porn,
  &:hover .badge-video {
    opacity: 0;
    transform: scale(1.2) translateY(-50px);
    transition: all .3s ease;
  }

  picture {
    display: block;
    overflow: hidden;
    height: 100%;
    border-radius: 8px;
    background: black;
  }
}

@supports (display: grid) {
  main {
    display: grid;
    grid-row-gap: 20px;
    justify-content: space-between;
    grid-template-columns: repeat(5, 235px);
    grid-template-rows: repeat(5, 352px);
    grid-auto-rows: auto;

    @media (max-width: 1280px) {
      grid-template-columns: repeat(4, 240px);
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 240px);
    }

    @media (max-width: 770px) {
      grid-template-columns: repeat(2, 240px);
    }
    @media (max-width: 540px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
    }
  }
  .character {
    width: 100%;
    margin: 0;

    @media (max-width: 520px) {
      width: 160px;
      height: 238px;
    }
  }
}

.character_img {
  display: block;
  width: 100%;
  height: 352px;
  overflow: hidden;
  object-fit: cover;
  transition: all .4s ease;
  mask-image: linear-gradient( rgba(0,0,0,1) 50%, rgba(0,0,0,.1));

  @media (max-width: 520px) {
    height: 100%;
  }
}

.badge-wrapper {
  position: absolute;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 20px;
  right: 0px;
}

.badge-verified {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: rgba($color: #ffffff, $alpha: .6);
  backdrop-filter: blur(4px);
  padding: 3px 6px;
  margin-bottom: 10px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  span {
    line-height: 1;
  }

  @media (max-width: 520px) {
    span {
      display: none;
    }
  }
}

.badge-vip {
  font-size: 20px;
  background-image: linear-gradient(180deg,#f1d119,#cc9505);
  background-image: linear-gradient(180deg, rgba($color: #f1d119, $alpha: .8),rgba($color: #cc9505, $alpha: .8) 98%);

  span {
    line-height: 1;
  }

  @media (max-width: 520px) {
    font-size: 16px;
  }
}

.badge-porn {
  font-size: 12px;
  background-image: linear-gradient(180deg,#be8fcd,#7a4490 98%);
  background-image: linear-gradient(180deg, rgba($color: #be8fcd, $alpha: .8),rgba($color: #7a4490, $alpha: .8) 98%);
  
  span {
    line-height: 1.2;
  }

  @media (max-width: 520px) {
    font-size: 10px;
  }
}

.badge-video {
  font-size: 12px;
  background-image: linear-gradient(180deg,#6aa8e2,#3b67e0 98%);
  background-image: linear-gradient(180deg, rgba($color: #6aa8e2, $alpha: .8),rgba($color: #3b67e0, $alpha: .8) 98%);
  
  span {
    line-height: 1;
  }

  @media (max-width: 520px) {
    font-size: 10px;
  }
}

.badge-porn, .badge-vip, .badge-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: -10px;
  margin-right: -12px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.3),inset 0 2px 0 0 hsla(0,0%,100%,.4);
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  transition-delay: .4s;

  @media (max-width: 520px) {
    height: 50px;
    width: 50px;
  }
}

.character > footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  padding: 10px;
}
.name_wrapper {
  display: flex;
  align-items: center;
}
.name_wrapper .name {
  display: inline-block;
  text-transform: capitalize;
  color: var(--white);
  margin-right: 6px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;

  @media (max-width: 520px) {
    font-size: 16px;
  }
}
.desc {
  display: block;
  color: var(--white);
  font-size: 16px;

  @media (max-width: 520px) {
    font-size: 12px;
  }
}
