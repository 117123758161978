/*=======================================*\
  Header
\*=======================================*/
.header {
  position: sticky;
  top: 0;
  background: rgba(0, 0, 0, .8);
  backdrop-filter: blur(2px);
}

.menu-wrapper {
  transition: 1s;
  position: relative;
  width: 100%;
}

.menu {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu .brand {
  color: white;
}

.menu > ul {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
}

.menu > ul li {
  color: var(--white);
  display: flex;
  height: 100%;
}

.menu a {
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  color: white;
}

.menu .brand a {
  padding: 0;

  img {
    height: 50px;
    width: 190px;
    object-fit: contain;

    @media (max-width: 600px) {
      height: 35px;
      width: 140px;
    }
  }
}

.menu {
  .menu-option {

    @media (max-width: 480px) {
      display: none;
    }

    &:hover {
      transition: 0.4s;
      background: rgba(204, 204, 204, 0.274);
    }
  }

  .button {
    height: 36px;
    --borderWidth: 8px;
    position: relative;
    border-radius: var(--borderWidth);
    background: #FF9000;
    color: black;
    font-weight: 600;

    @media (max-width: 480px) {
      height: 24px;
      font-size: 12px;
      padding: 0 10px;
    }
  }
}

// Custom border animation for button
#box {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}