.footer {
  min-height: 200px;
  background-color: black;
  color: white;

  nav {
    .link {
      &::after {
        transition: all 0.4s ease;
        content: '';
        display: block;
        height: 2px;
        width: 15%;
      }

      &:hover::after {
        width: 45%;
        background-color: #f33784;
      }
    }
  }
}
