/* @import '~reset-css'; */

@import '~normalize.css';

body {
  margin: 0;
  font-family: 'Montserrat';
  font-weight: 400;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  outline: none !important;
}

/*=======================================*\
  CSS Fonts
\*=======================================*/
@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat/Montserrat-Regular.eot');
    src: url('./fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('./fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
        url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
        url('./fonts/Montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat/Montserrat-Bold.eot');
    src: url('./fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('./fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
        url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'),
        url('./fonts/Montserrat/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



/*=======================================*\
  CSS Variables
\*=======================================*/

:root {
  --principal: #333;
  --secundario: #666;
  --white: white;
  --black: black;
  --grey: rgb(151, 151, 151);
  --light-grey: #ccc;
  --darkness-grey: #e6e6e6;
  --blue: #2699fb;
  --purple: #8a00ca;
  --fushia: #E6006A;
  --light-fushia: #ff68b4;
}
/*=======================================*\
  Scroll Personalizado
\*=======================================*/
/* Grosor del Scroll */
body::-webkit-scrollbar {
  width: 12px;
}
/* Track del Scroll */
body::-webkit-scrollbar-track {
  background: var(--darkness-grey);
}
/* Barra del Scroll */
body::-webkit-scrollbar-thumb {
  background: var(--light-grey);
  border-radius: 6px;
}
body::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
}

/*=======================================*\
  Estilos Principales
\*=======================================*/
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
  display: block;
}

h1,
h2,
h3,
p {
  margin: 0;
}
.container {
  margin: 0 auto;
}
.pt-30 {
  padding-top: 30px;
}

.dn {
  display: none;
}
.bg-black {
  background: black;
}
.h1 {
  padding-top: 10px;
  font-weight: normal;
}

/*=======================================*\
  Loader
\*=======================================*/
.lds-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--fushia);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Pantallas HD 1360  */
@media (min-width: 1200px) and (max-width: 1500px) {
  .container {
    width: 1280px;
  }
}
/*=======================================*\
  Pantallas 4k
\*=======================================*/
@media (min-width: 1501px) {
  .container {
    width: 1365px;
  }
}
/*=======================================*\
  Tablets - iPads
\*=======================================*/
@media (max-width: 800px) {
}
/*=======================================*\
  Para móviles
\*=======================================*/
@media only screen and (max-width: 480px) {
  .carousel-image {
    height: 170px;
    width: 118px;
  }
}